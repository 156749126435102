import React, { useState } from "react";
import _last from "lodash/last";
import _head from "lodash/head";
import * as messagesActions from "../../../../store/messages/actions";
import { fileCheck, saveMediaObject } from "../../../../services/formServiceFunctions";
import _isFunction from "lodash/isFunction";
import FileUploadForm, { FileUploadFormProps } from "./FileUploadForm";
import _filter from "lodash/filter";
import _get from "lodash/get";
import _cloneDeep from "lodash/cloneDeep";
import _set from "lodash/set";
import { useMutation } from "@tanstack/react-query";
import { createMediaObject } from "../../../../services/reactQuery/reactQueryMediaObjectService";
import { updateResource } from "../../../../services/reactQuery/reactQueryService";
import { isAllowMultiplePagesFileType } from "../../../../services/mediaObjects";
import { AbstractCase } from "../../../../types/Entities/AbstractCase";
import { MediaObject } from "../../../../types/Entities/MediaObject/MediaObject";
import { Child } from "../../../../types/Entities/Child";
import { MediaObjectCombinationRequest } from "../../../../types/Entities/MediaObject/MediaObjectCombinationRequest";

type FileUploadSectionProps = {
  productEntity: AbstractCase | Child;
  temporaryMediaObjectsFieldname?: string;
  fileIndex?: number;
  setIsBlocked: (isBlocked: boolean) => void;
  isBlocked: boolean;
  description?: string;
  product: string;
  mediaObjectCombinationRequest: MediaObjectCombinationRequest;
  productEntityId: number;
  postSubmitCallback?: () => void;
  updateAction: (id: number, data: Partial<MediaObjectCombinationRequest>) => Promise<void>;
  fieldName: string;
  withUploadNotification?: boolean;
  allowAudioAndVideo?: boolean;
} & Omit<FileUploadFormProps, "label" | "mediaObjectCollection" | "deleteMediaObject" | "handleFileChange" | "fieldname" | "filePath">;

const FileUploadSection = ({
  productEntity,
  temporaryMediaObjectsFieldname = "temporaryFilesToCombine",
  fileIndex = 0,
  setIsBlocked,
  isBlocked,
  description = "Hochgeladene Datei",
  product,
  productEntityId,
  postSubmitCallback,
  updateAction,
  fieldName,
  withUploadNotification,
  allowAudioAndVideo,
  mediaObjectCombinationRequest,
  ...rest
}: FileUploadSectionProps) => {
  const [filePath, setFilePath] = useState("");

  const updateMutation = useMutation(updateResource);
  const createMediaObjectMutation = useMutation(createMediaObject);

  const mediaObjectCollection: MediaObject[] = _get(mediaObjectCombinationRequest, ["filesToCombine", fileIndex, "files"], []);

  const resetState = () => {
    setFilePath("");
    setIsBlocked(false);
  };

  const handleChange = async ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    if (mediaObjectCollection.length > 0 && !isAllowMultiplePagesFileType(target.files![0].name)) {
      messagesActions.addMessage({
        type: "error",
        text: "Die Datei kann leider nicht hochgeladen werden. Sie können entweder einzelne Dateien auswählen oder alternativ mehrere Bilder (JPG/PNG), die dann zu einem PDF Dokument zusammengefügt werden.",
      });
      resetState();
      return;
    }

    const fileCheckRes = fileCheck(target, allowAudioAndVideo);
    if (fileCheckRes.hasError) {
      messagesActions.addMessage({ type: "error", text: fileCheckRes.errorMsg });
      return;
    }

    const file = _head(target.files);
    if (!file) {
      return;
    }
    setFilePath(file.name);
    await uploadFile(file);
  };

  const uploadFile = async (file: File) => {
    setIsBlocked(true);

    const mediaObject = await saveMediaObject(createMediaObjectMutation, {
      file: file,
      fieldname: temporaryMediaObjectsFieldname,
      description: description,
      product: product,
      productId: productEntityId || productEntity.id,
    });

    if (mediaObject === false) {
      resetState();
      return;
    }

    let files = _cloneDeep(mediaObjectCollection);
    mediaObject.thumbnail = null;
    files.push(mediaObject);

    let fileArray = _cloneDeep(_get(mediaObjectCombinationRequest, "filesToCombine", []));
    _set(fileArray, [fileIndex, "files"], files);
    _set(fileArray, [fileIndex, "description"], description);
    _set(fileArray, [fileIndex, "fieldname"], "combinedMediaObject");

    await updateAction(mediaObjectCombinationRequest.id, {
      filesToCombine: fileArray,
    });

    _isFunction(postSubmitCallback) && postSubmitCallback();
    resetState();
  };

  const deleteMediaObject = async (mediaObjectToDelete: MediaObject) => {
    let fileArray = _cloneDeep(_get(mediaObjectCombinationRequest, "filesToCombine", []));
    const files = _filter(mediaObjectCollection, (mediaObject) => mediaObject.id !== mediaObjectToDelete.id);
    _set(fileArray, [fileIndex, "files"], files);

    await updateMutation.mutateAsync({ uri: "/media_objects", id: mediaObjectToDelete.id, data: { deleted: true } });
    await updateAction(mediaObjectCombinationRequest.id, { filesToCombine: fileArray });
    _isFunction(postSubmitCallback) && postSubmitCallback();
  };

  const isPdfUploaded = () => {
    return mediaObjectCollection.length > 0 && _head(mediaObjectCollection)!.mimeType === "application/pdf";
  };

  return (
    <FileUploadForm
      label={""}
      mediaObjectCollection={mediaObjectCollection}
      deleteMediaObject={deleteMediaObject}
      handleFileChange={handleChange}
      fieldname={fieldName}
      allowMore={
        allowAudioAndVideo ? mediaObjectCollection.length === 0 || isAllowMultiplePagesFileType(_last(mediaObjectCollection)?.originalName) : !isPdfUploaded()
      }
      filePath={filePath}
      isBlocked={isBlocked}
      {...rest}
    />
  );
};

export default FileUploadSection;
